import { Optional } from '@pogokid/util'
import { getPublicConfig } from '~/config'

export function profilePath(
  username: Optional<string> = 'me',
  useAbsolute = false
) {
  const pathname = `/@${username}`
  return useAbsolute
    ? getPublicConfig().callbackBaseUrl + pathname
    : pathname
}
