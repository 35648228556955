/// <reference types="vite-plugin-pwa/react" />
import { ConnectionManagerProvider } from '@soniq/public-manager-connection'
import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
} from 'react'
import { AuthProvider } from '~m/auth/AuthProvider'
import { PaymentsManagerProvider } from '@soniq/public-manager-payments'
import { getPublicConfig } from '~/config.ts'
import { IntegrateManagerProvider } from '@soniq/public-manager-integrate'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { DURATION_HOUR } from '@pogokid/util/datetime'
import { getAllLogger } from '~/logger.ts'
import { PwaStatus } from '~m/AppUi/components/PwaStatus.tsx'
import { SchedulingManagerProvider } from '@soniq/public-manager-scheduling'

const log = getAllLogger('ui:AppUiProvider')

interface AppUiProviderState {
  pwaOfflineReady: boolean
  pwaNeedsRefresh: boolean
  isQuickSearchOpen: boolean
}

interface AppUiContextProps extends AppUiProviderState {
  /**
   * Reloads the current window to allow the service worker take the control.
   */
  updatePwa: () => Promise<void>
  showSearch: (yes: boolean) => void
}

interface AppUiProviderProps {}

const AppUiContext = createContext<AppUiContextProps>(
  {} as never
)

export function useAppUiContext() {
  return useContext(AppUiContext)
}

export const AppUiProvider: FC<
  React.PropsWithChildren<AppUiProviderProps>
> = ({ children }) => {
  const [isQuickSearchOpen, setIsQuickSearchOpen] =
    useState(false)
  const {
    offlineReady: [offlineReady],
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(
      swUrl: string,
      swRegistration: ServiceWorkerRegistration | undefined
    ) {
      log.info('Registered service worker', swRegistration)
      if (swRegistration?.active?.state === 'activated') {
        registerPeriodicSync(
          DURATION_HOUR,
          1_000,
          swUrl,
          swRegistration
        )
      } else if (swRegistration?.installing) {
        swRegistration.installing.addEventListener(
          'statechange',
          (e) => {
            const sw = e.target as ServiceWorker
            if (sw.state === 'activated')
              registerPeriodicSync(
                DURATION_HOUR,
                DURATION_HOUR,
                swUrl,
                swRegistration
              )
          }
        )
      }
    },
  })

  const value = useMemo((): AppUiContextProps => {
    return {
      pwaOfflineReady: offlineReady,
      pwaNeedsRefresh: needRefresh,
      updatePwa: updateServiceWorker,
      isQuickSearchOpen,
      showSearch: (yes) => {
        setIsQuickSearchOpen(yes)
      },
    }
  }, [
    isQuickSearchOpen,
    needRefresh,
    offlineReady,
    updateServiceWorker,
  ])

  return (
    <AppUiContext.Provider value={value}>
      <AuthProvider>
        <ConnectionManagerProvider
          apiBaseUrl={getPublicConfig().apiBaseUrl}
        >
          <PaymentsManagerProvider
            apiBaseUrl={getPublicConfig().apiBaseUrl}
          >
            <IntegrateManagerProvider
              apiBaseUrl={getPublicConfig().apiBaseUrl}
            >
              <SchedulingManagerProvider
                apiBaseUrl={getPublicConfig().apiBaseUrl}
              >
                <PwaStatus />
                {children}
              </SchedulingManagerProvider>
            </IntegrateManagerProvider>
          </PaymentsManagerProvider>
        </ConnectionManagerProvider>
      </AuthProvider>
    </AppUiContext.Provider>
  )
}

/**
 * This function will register a periodic sync check every hour, you can modify the interval as needed.
 * @param period {number} How long between checks
 * @param delay {number}  How long before first check
 * @param swUrl {string}  Service worker URL to check
 * @param r {ServiceWorkerRegistration}
 */
function registerPeriodicSync(
  period: number,
  delay: number,
  swUrl: string,
  r: ServiceWorkerRegistration
) {
  if (period <= 0) return

  async function checkSW() {
    if ('onLine' in navigator && !navigator.onLine) return

    try {
      const resp = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          cache: 'no-store',
          'cache-control': 'no-cache',
        },
      })

      if (resp?.status === 200) await r.update()
    } catch (error) {
      log.error('Failed to check for new service worker', error)
    }

    // Register another check after the designated period
    setTimeout(checkSW, period)
  }

  return setTimeout(checkSW, delay)
}
