import { FC, useMemo } from 'react'
import {
  IntegrateContext,
  IntegrateContextProps,
} from './context.tsx'

interface IntegrateProviderProps {
  apiBaseUrl: string
}

export const IntegrateManagerProvider: FC<
  React.PropsWithChildren<IntegrateProviderProps>
> = ({ children, apiBaseUrl }) => {
  const value = useMemo((): IntegrateContextProps => {
    return {
      apiBaseUrl,
    }
  }, [apiBaseUrl])

  return (
    <IntegrateContext.Provider value={value}>
      {children}
    </IntegrateContext.Provider>
  )
}
