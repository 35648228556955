import { SupabaseClient } from '@pogokid/supabase'
import { fetchJson } from '@soniq/public-resource'
import { SupabaseReadWriteStore } from '@soniq/public-resource/supabase'
import {
  InvoiceLineWithId,
  InvoiceSchema,
  InvoiceWithId,
  PayPaymentProvider,
  validateInvoice,
} from '@soniq/schema-payments'

export class InvoiceStore extends SupabaseReadWriteStore<
  'invoice',
  InvoiceSchema
> {
  static new() {
    return new InvoiceStore('invoice', validateInvoice)
  }

  async fetchByProfileId(db: SupabaseClient, profileId: string) {
    return this.runQuery(
      db,
      async (table) =>
        await table
          .select<'*', InvoiceWithId>()
          .match({ bill_from_profile_id: profileId })
    )
  }

  async fetchByContactId(db: SupabaseClient, contactId: string) {
    return this.runQuery(
      db,
      async (table) =>
        await table
          .select<'*', InvoiceWithId>()
          .match({ bill_to_contact_id: contactId })
    )
  }
}

export async function getPayData(
  apiBaseUrl: string,
  link: string
) {
  const { data, error } = await fetchJson<{
    ok: boolean
    logo_path: string
    invoice: InvoiceWithId
    invoice_lines: InvoiceLineWithId[]
    payment_providers: PayPaymentProvider[]
  }>({
    method: 'get',
    baseUrl: apiBaseUrl,
    endpoint: '/pay',
    query: new URLSearchParams([['link', link]]),
  })
  if (error) throw new Error(error.message)
  return { data }
}
