import React, {
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
} from 'react'
import { Box, IconButton, Typography } from '@pogokid/mui'
import CloseIcon from '@mui/icons-material/Close'
import { styled, Toolbar } from '@mui/material'

export interface ActionSectionButton {
  id: string
  title: string
  icon: ReactNode
}

interface ActionSectionProps {
  title: string
  defaultExpanded?: boolean
  buttons?: ActionSectionButton[]

  onButtonClick?(buttonId: string): void

  onClose?(): void
}

export const ActionSection: FC<
  React.PropsWithChildren<ActionSectionProps>
> = ({
  title,
  defaultExpanded,
  buttons,
  onButtonClick,
  onClose,
  children,
}) => {
  const onButtonsClick = useCallback(
    (id: string, ev: MouseEvent<HTMLButtonElement>) => {
      if (onButtonClick) {
        onButtonClick(id)
      }
      ev.stopPropagation()
    },
    [onButtonClick]
  )
  return (
    <ActionSectionWrapper>
      <Toolbar disableGutters variant="dense">
        <Typography
          component={'h2'}
          variant={'body2'}
          color={'neutral.main'}
          gutterBottom={false}
        >
          {title}
        </Typography>
        <Box flexGrow={1} />
        {buttons && buttons.length > 0 && (
          <>
            {buttons.map(({ id, title, icon }) => (
              <IconButton
                key={id}
                title={title}
                data-button-id={id}
                size={'small'}
                onClick={(ev) => onButtonsClick(id, ev)}
              >
                {icon}
              </IconButton>
            ))}
          </>
        )}
        {onClose && (
          <IconButton size={'small'} onClick={onClose}>
            <CloseIcon fontSize={'inherit'} />
          </IconButton>
        )}
      </Toolbar>
      <Box>{children}</Box>
    </ActionSectionWrapper>
  )
}

const ActionSectionWrapper = styled('div')(
  ({ theme }) => `
  padding: 0 ${theme.spacing(1)} ${theme.spacing(
    2
  )} ${theme.spacing(1)};
  border-bottom: 1px solid ${theme.palette.divider};
`
)
