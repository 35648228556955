import { IntervalDates, IntervalISOString } from './types'

export const DURATION_MINUTE = 60000
export const DURATION_FIVE_MINUTES = 300000
export const DURATION_HOUR = 3600000
export const DURATION_DAY = 86400000

export function normaliseIntervalToDates(
  interval: IntervalISOString | IntervalDates
): IntervalDates {
  return {
    start: new Date(interval.start),
    end: new Date(interval.end),
  }
}

export function fromIsoIntervalToDates(
  interval: IntervalISOString
): IntervalDates {
  return {
    start: new Date(interval.start),
    end: new Date(interval.end),
  }
}
