import { styled, Typography } from '@mui/material'
import { Toolbar } from '@pogokid/mui'
import React, { FC } from 'react'

interface TopToolbarProps {
  heading?: string
}

const StyledToolbar = styled(Toolbar)(
  ({ theme }) => `
  z-index: ${theme.zIndex.drawer - 1};
  position: sticky;
  inset: 0 0 auto 0;
  background-color: ${theme.palette.background.default};
  border-bottom: solid 1px ${theme.palette.divider};
  gap: 1rem;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    width: 100vw;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
`
)

const StyledHeading = styled(Typography)(`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`)

export const TopToolbar: FC<
  React.PropsWithChildren<TopToolbarProps>
> = ({ heading, children }) => {
  return (
    <>
      <StyledToolbar disableGutters={false} variant={'dense'}>
        {heading && (
          <StyledHeading mx={0} variant={'h4'}>
            {heading}
          </StyledHeading>
        )}
        {children}
      </StyledToolbar>
    </>
  )
}
