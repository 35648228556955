import { SupabaseReadWriteStore } from '@soniq/public-resource/supabase'
import {
  IntegrationConnectionSchema,
  validateIntegrationConnection,
} from '@soniq/schema-integrate'
import { SupabaseClient } from '@pogokid/supabase'
import {
  fetchWithAuth,
  SingleResponse,
} from '@soniq/public-resource'

export class IntegrationConnectionStore extends SupabaseReadWriteStore<
  'integration_connection',
  IntegrationConnectionSchema
> {
  static new() {
    return new IntegrationConnectionStore(
      'integration_connection',
      validateIntegrationConnection
    )
  }
}

export interface DeleteIntegrationResponseData {
  ok: boolean
}

export async function deleteIntegrationConnection(
  apiBaseUrl: string,
  db: SupabaseClient,
  integrationHandle: string,
  profileId: string
): Promise<SingleResponse<DeleteIntegrationResponseData>> {
  const { data, error } =
    await fetchWithAuth<DeleteIntegrationResponseData>({
      db,
      baseUrl: apiBaseUrl,
      endpoint: `/integration/${integrationHandle}/${profileId}`,
      method: 'delete',
    })
  if (error) {
    throw new Error('Not able to delete integration connection')
  } else {
    return { data }
  }
}
