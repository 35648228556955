import { SupabaseClient } from '@pogokid/supabase'
import {
  setEntityMutations,
  setEntityStoreMutations,
} from '@soniq/public-manager'
import {
  invoiceStore,
  InvoiceStore,
  PriceStore,
  priceStore,
  updateInvoiceLine,
  upsertInvoiceLines,
} from '@soniq/public-resource-payments'
import {
  InvoiceLineSchema,
  InvoiceSchema,
  PriceSchema,
} from '@soniq/schema-payments'
import { QueryClient } from '@tanstack/react-query'
import { invoiceKeys, invoiceLineKeys, priceKeys } from './keys'

export function initPaymentsManagerEntities(
  apiBaseUrl: string,
  queryClient: QueryClient,
  db: SupabaseClient
) {
  // Invoice
  setEntityStoreMutations<
    InvoiceSchema,
    typeof invoiceKeys,
    InvoiceStore
  >({
    apiBaseUrl,
    queryClient,
    entityKeys: invoiceKeys,
    store: invoiceStore,
    db,
  })

  // Invoice Line
  setEntityMutations<InvoiceLineSchema, typeof invoiceLineKeys>({
    queryClient,
    entityKeys: invoiceLineKeys,
    upsertEntities: async ({ data }) => {
      return upsertInvoiceLines(db, data)
    },
    updateEntity: async ({ data }) => {
      return updateInvoiceLine(db, data.id, data)
    },
    deleteEntity: async (id) => {
      throw new Error(`Delete not implemented: ${id}`)
    },
  })

  // Price
  setEntityStoreMutations<
    PriceSchema,
    typeof priceKeys,
    PriceStore
  >({
    apiBaseUrl,
    queryClient,
    entityKeys: priceKeys,
    store: priceStore,
    db,
  })
}
