import { ComponentType } from 'react'
import { useAsync } from '@pogokid/react-hooks'

export type WithWasmProps<WasmModule extends object> = {
  wasm: WasmModule
}

export function WithWasm<
  WasmModule extends object,
  Props extends WithWasmProps<WasmModule>
>(
  initWasm: () => Promise<WasmModule>,
  Component: ComponentType<Props>
): ComponentType<Omit<Props, 'wasm'>> {
  return function (props: Omit<Props, 'wasm'>) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const wasm = useAsync(initWasm)
    return wasm.value ? (
      <Component {...(props as Props)} wasm={wasm.value} />
    ) : null
  }
}
