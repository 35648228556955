import { PropsWithChildren, useMemo } from 'react'
import {
  SchedulingContext,
  SchedulingContextProps,
} from './context'
import { initWasm, WasmModule } from '../wasm'
import { WithWasm, WithWasmProps } from '@soniq/public-manager'

interface SchedulingProviderProps {
  apiBaseUrl: string
}

export const SchedulingManagerProvider = WithWasm(
  initWasm,
  ({
    children,
    apiBaseUrl,
    wasm,
  }: PropsWithChildren<
    SchedulingProviderProps & WithWasmProps<WasmModule>
  >) => {
    const value = useMemo((): SchedulingContextProps => {
      return {
        apiBaseUrl,
        manager: wasm,
      }
    }, [apiBaseUrl, wasm])

    return (
      <SchedulingContext.Provider value={value}>
        {children}
      </SchedulingContext.Provider>
    )
  }
)

SchedulingManagerProvider.displayName =
  'SchedulingManagerProvider'
