import { SchemaWithId } from '@soniq/schema'
import {
  matchQuery,
  Query,
  QueryClient,
  QueryFunction,
  QueryKey,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import {
  AsyncQueryResponse,
  QueryResponse,
  ResponseWithRefetch,
  SingleResponse,
} from '@soniq/public-resource'
import { EntityKeys } from '../types.ts'
import { isArrayWithLength, Optional } from '@pogokid/util'
import { useMemo } from 'react'
import { isString } from '@pogokid/util/lodash'
import { getAllLogger } from '@pogokid/log'

const log = getAllLogger('useEntitiesList')

export function entityListFilterPredicate<
  Filters extends Record<string, string | number>
>(filter: Partial<Filters>) {
  const filterEntries = Object.entries(filter)
  return function (query: Query): boolean {
    if (isArrayWithLength(query.queryKey)) {
      const [_entity, keyType, filters] = query.queryKey
      if (
        keyType === 'list' &&
        typeof filters === 'object' &&
        !!filters
      ) {
        const match = filterEntries.every(([key, value]) => {
          return (
            key in filters && (filters as Filters)[key] === value
          )
        })
        log.verbose('List predicate match:', query.queryKey)
        return match
      }
    }
    return false
  }
}

export function useEntitiesList<
  Schema extends SchemaWithId<unknown>
>(
  listKey: QueryKey,
  options: Omit<
    UseQueryOptions<
      QueryResponse<Schema> | null,
      Error,
      QueryResponse<Schema>
    >,
    'queryKey'
  >
): AsyncQueryResponse<Schema> & ResponseWithRefetch {
  const { data, isLoading, error, refetch } = useQuery({
    ...options,
    enabled: options.enabled !== false,
    queryKey: listKey,
  })
  return {
    value: data?.data,
    loading: isLoading,
    error: error as Error | null | undefined,
    refetch,
  }
}

export interface UseEntitiesIndexedListOptions<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, unknown>>
> {
  listKey: QueryKey
  entityKeys: Keys
  queryFn: QueryFunction<QueryResponse<Schema>, QueryKey, never>
  options: Omit<
    UseQueryOptions<
      QueryResponse<string>,
      Error,
      QueryResponse<string>
    >,
    'queryKey' | 'queryFn'
  >
}

export function useEntitiesIndexedList<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, unknown>>
>(
  options: UseEntitiesIndexedListOptions<Schema, Keys>
): AsyncQueryResponse<Schema> & ResponseWithRefetch {
  const queryClient = useQueryClient()
  const {
    data: queryData,
    isLoading,
    error,
    refetch,
    dataUpdatedAt,
  } = useQuery<QueryResponse<string>>(
    buildEntitiesIndexedListQuery(queryClient, options)
  )
  const indexedListData: Schema[] = useMemo(() => {
    log.verbose('Indexed List data updated', dataUpdatedAt)
    return getIndexedDetailData(
      queryData?.data,
      queryClient,
      options.entityKeys
    )
  }, [
    dataUpdatedAt,
    queryData?.data,
    options.entityKeys,
    queryClient,
  ])

  return {
    value: indexedListData,
    loading: isLoading,
    error: error as Error | null | undefined,
    refetch,
  }
}

export type UseEntitiesDynamicIndexedListOptions<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, unknown>>
> = Omit<
  UseEntitiesIndexedListOptions<Schema, Keys>,
  'listKey' | 'queryFn'
> & {
  queries: Array<{
    listKey: QueryKey
    queryFn: QueryFunction<
      QueryResponse<Schema>,
      QueryKey,
      never
    >
  }>
}

export function useEntitiesDynamicIndexedList<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, unknown>>
>(
  options: UseEntitiesDynamicIndexedListOptions<Schema, Keys>
): AsyncQueryResponse<Schema> {
  const queryClient = useQueryClient()
  const { data, dataUpdatedAt, isLoading, error } = useQueries({
    queries: options.queries.map(({ listKey, queryFn }) =>
      buildEntitiesIndexedListQuery(queryClient, {
        listKey,
        entityKeys: options.entityKeys,
        queryFn,
        options: options.options,
      })
    ),
    combine: (results) => {
      const dataUpdatedAt = results.reduce(
        (m, q) => (q.dataUpdatedAt > m ? q.dataUpdatedAt : m),
        0
      )
      return {
        data: results
          .flatMap((result) => result.data?.data)
          .filter(isString),
        dataUpdatedAt,
        error: results.find((result) => result.error),
        isLoading: !!results.find((result) => result.isLoading),
      }
    },
  })
  const indexedListData: Schema[] = useMemo(() => {
    log.verbose(
      `Dynamic Indexed List data updated`,
      options.entityKeys.all,
      dataUpdatedAt
    )
    return getIndexedDetailData(
      data,
      queryClient,
      options.entityKeys
    )
  }, [data, dataUpdatedAt, options.entityKeys, queryClient])

  return {
    value: indexedListData,
    loading: isLoading,
    error: error as Error | null | undefined,
  }
}

export function buildEntitiesIndexedListQuery<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, unknown>>
>(
  queryClient: QueryClient,
  {
    listKey,
    entityKeys,
    queryFn,
    options,
  }: UseEntitiesIndexedListOptions<Schema, Keys>
): UseQueryOptions<
  QueryResponse<string>,
  Error,
  QueryResponse<string>
> {
  return {
    ...options,
    enabled: options?.enabled !== false,
    queryKey: listKey,
    queryFn: async (opts): Promise<QueryResponse<string>> => {
      if (typeof queryFn === 'function') {
        const response = await queryFn(opts)
        if (
          !response.error &&
          isArrayWithLength(response?.data)
        ) {
          await setEntityDetailData(
            queryClient,
            entityKeys,
            response.data,
            [listKey]
          )
        }
        return {
          ...response,
          data:
            response?.data.map((entity) => entity.id) ?? null,
        } as QueryResponse<string>
      }
      return { data: [] } as QueryResponse<string>
    },
  }
}

export function getIndexedDetailData<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, unknown>>
>(
  data: Optional<string[]>,
  queryClient: QueryClient,
  entityKeys: Keys
) {
  if (isArrayWithLength(data)) {
    return data
      .map(
        (id): Schema | null | undefined =>
          queryClient.getQueryData<SingleResponse<Schema>>(
            entityKeys.detail(id)
          )?.data
      )
      .filter((data) => !!data)
  } else {
    return []
  }
}

export async function setEntityDetailData<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, unknown>>
>(
  queryClient: QueryClient,
  entityKeys: Keys,
  data: Schema[],
  ignoreKeys: QueryKey[] = []
) {
  const lists = queryClient.getQueriesData<{ data: string[] }>({
    queryKey: entityKeys.lists(),
    predicate(q) {
      const data = q.state.data
      if (
        ignoreKeys.length &&
        matchQuery({ queryKey: ignoreKeys[0] }, q)
      ) {
        return false
      }
      if (!!data && typeof data === 'object' && 'data' in data) {
        return isArrayWithLength(data.data)
      }
      return false
    },
  })
  const changedLists = new Map<QueryKey, { data: string[] }>()
  await Promise.all(
    data.map(async (entity) => {
      await queryClient.setQueryData(
        entityKeys.detail(entity.id),
        {
          data: entity,
        }
      )
      for (const [listKey, list] of lists) {
        if (
          !changedLists.has(listKey) &&
          list?.data.includes(entity.id)
        ) {
          changedLists.set(listKey, list)
          log.verbose('List change applied', listKey, list)
        }
      }
    })
  )
  for (const [listKey, list] of changedLists.entries()) {
    queryClient.setQueryData(listKey, { ...list })
  }
}
