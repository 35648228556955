import { SubscriptionPlanType } from '@soniq/schema-payments'
import { profilePath } from '~m/profile/paths.ts'

export function accountDetailPath() {
  return '/account'
}

export function accountProfilePath() {
  return accountDetailPath()
}

export function accountEmailsPath() {
  return `${accountDetailPath()}/emails`
}

export function accountSecurityPath() {
  return `${accountDetailPath()}/security`
}

export function accountBillingPath() {
  return `${accountDetailPath()}/billing`
}

export function accountPaymentsPath() {
  return `${accountDetailPath()}/payments`
}

export function accountPaymentsOnboardingPath(
  username?: string
) {
  return `${profilePath(username)}/payments/onboarding`
}

export function accountIntegrationsPath() {
  return `${accountDetailPath()}/integrations`
}

export function subscribePath(planType: SubscriptionPlanType) {
  return `/subscribe?plan_type=${planType}`
}
