import { ISODateTimeString } from './types'
import { Optional } from '../types'

/**
 * Normalise an ISO Date Time string to use Z instead of +00:00
 * 2024-06-23T07:23:35.299Z
 *
 * @param isoDateTimeString
 */
export function normaliseIsoDateTimeString<
  T extends Optional<ISODateTimeString>
>(isoDateTimeString: T) {
  return isoDateTimeString
    ? new Date(isoDateTimeString).toISOString()
    : isoDateTimeString
}

export function formatUtcIsoDate(date: Date) {
  return [
    date.getUTCFullYear(),
    (date.getUTCMonth() + 1).toString().padStart(2, '0'),
    date.getUTCDate().toString().padStart(2, '0'),
  ].join('-')
}

export function formatLocalIsoDate(date: Date) {
  return [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
  ].join('-')
}
