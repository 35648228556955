import { ScheduleSchema } from '@soniq/schema-scheduling'
import { isFuture, isPast } from '@pogokid/util/datetime'

export function isScheduleInPast (schedule: ScheduleSchema) {
  if (!schedule.event_start) return false
  return isPast(new Date(schedule.event_start))
}

export function isScheduleInFuture (schedule: ScheduleSchema) {
  if (!schedule.event_start) return true
  return isFuture(new Date(schedule.event_start))
}
