import { QueryClient } from '@tanstack/react-query'
import { isArrayWithLength } from '@pogokid/util'
import { scheduleKeys } from './keys'
import {
  entityListFilterPredicate,
  isEntitiesListData,
} from '@soniq/public-manager'
import { getAllLogger } from '@pogokid/log'

const log = getAllLogger(
  'public-manager-scheduling:invalidation'
)

export async function refreshScheduleListQueries({
  queryClient,
  scheduleIds,
  fromEventStart,
}: {
  queryClient: QueryClient
  scheduleIds: string[]
  fromEventStart?: Date
}) {
  await queryClient.invalidateQueries({
    predicate(query) {
      if (
        isArrayWithLength(query.queryKey) &&
        query.queryKey.length === 3
      ) {
        const [allKey, keyType, filters] = query.queryKey
        if (
          allKey === scheduleKeys.all[0] &&
          keyType === 'list' &&
          scheduleKeys.isFilters(filters)
        ) {
          const data = queryClient.getQueryData(query.queryKey)
          // check that the filter start date is inside the interval
          const isWithin = fromEventStart
            ? !!filters.start &&
              new Date(filters.start) >= fromEventStart
            : true
          // check that the list includes an id passed in
          const includesSchedule =
            isEntitiesListData(data) &&
            scheduleIds.some((id) => data.data.includes(id))
          log.verbose(
            'Schedule List invalidation:',
            query.queryKey,
            includesSchedule || isWithin
          )
          return includesSchedule || isWithin
        }
      }
      return false
    },
  })
  await queryClient.refetchQueries({
    predicate(query) {
      if (
        isArrayWithLength(query.queryKey) &&
        query.queryKey.length === 3
      ) {
        const [allKey, keyType, filters] = query.queryKey
        if (
          allKey === scheduleKeys.all[0] &&
          keyType === 'list' &&
          scheduleKeys.isFilters(filters)
        ) {
          // check that the filter start date is inside the interval
          return fromEventStart
            ? !!filters.start &&
                new Date(filters.start) >= fromEventStart
            : true
        }
      }
      return false
    },
  })
}

export async function refreshScheduleInstanceListQueries({
  queryClient,
  scheduleIds,
}: {
  queryClient: QueryClient
  scheduleIds: string[]
}) {
  await Promise.all(
    scheduleIds.map((scheduleId) => {
      return queryClient.refetchQueries({
        predicate: entityListFilterPredicate({
          scheduleId: scheduleId,
        }),
      })
    })
  )
}
