import { SupabaseClient } from '@pogokid/supabase'
import {
  RowOf,
  SupabaseReadWriteDeleteStore,
} from '@soniq/public-resource/supabase'
import {
  ScheduleInstanceSchema,
  ScheduleInstanceWithId,
  validateScheduleInstance,
} from '@soniq/schema-scheduling'
import { Database } from '@soniq/util-supabase'
import { QueryResponse } from '@soniq/public-resource'
import { SchemaWithId } from '@soniq/schema'
import { Optional } from '@pogokid/util'
import { normaliseIsoDateTimeString } from '@pogokid/util/datetime'

export class ScheduleInstanceStore extends SupabaseReadWriteDeleteStore<
  'schedule_instance',
  ScheduleInstanceSchema
> {
  static new() {
    return new ScheduleInstanceStore(
      'schedule_instance',
      validateScheduleInstance
    )
  }

  protected mapQueryData<
    Returns = SchemaWithId<ScheduleInstanceSchema>
  >(
    data: Optional<
      Array<RowOf<'schedule_instance'> | ScheduleInstanceSchema>
    >
  ): Returns[] {
    const returns = super.mapQueryData(data)
    return returns.map((row) => ({
      ...row,
      event_start: normaliseIsoDateTimeString(row.event_start),
      recurrence_id: normaliseIsoDateTimeString(
        row.recurrence_id
      ),
    })) as Returns[]
  }

  async fetchByScheduleId(
    db: SupabaseClient,
    scheduleIds: string[]
  ): Promise<QueryResponse<ScheduleInstanceWithId>> {
    return this.runQuery(
      db,
      async (table) =>
        await table.select().in('schedule_id', scheduleIds)
    )
  }
}

export const scheduleInstanceCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('schedule_instance')
