import { getVerboseLogger } from '@pogokid/log'
import { newId, SupabaseClient } from '@pogokid/supabase'
import {
  getDay,
  ISODateTimeString,
  set,
  setDay,
} from '@pogokid/util/datetime'
import { isEmpty } from '@pogokid/util/lodash'
import {
  scheduleInstanceStore,
  scheduleStore,
} from '@soniq/public-resource-scheduling'
import { renewCreatedFields } from '@soniq/schema'
import {
  ScheduleSchema,
  ScheduleWithId,
} from '@soniq/schema-scheduling'
import { initWasm, MoveScheduleOptions } from '../wasm'
import { Database } from '@soniq/util-supabase'
import { isArrayWithLength } from '@pogokid/util'
import { isScheduleInFuture } from '../util'

const verbose = getVerboseLogger('ui:schedule:store')

type SetDayParams = Parameters<typeof setDay>
export type SetDayOptions = SetDayParams[2]

export async function moveSchedule(
  db: SupabaseClient<Database>,
  options: MoveScheduleOptions
) {
  const { moveSchedule } = await initWasm()
  const result = moveSchedule(options)
  if (isArrayWithLength(result.changedSchedules)) {
    await scheduleStore.upsert(db, result.changedSchedules)
  }
  if (isArrayWithLength(result.movedInstances)) {
    await scheduleInstanceStore.upsert(db, result.movedInstances)
  }
  return result
}

export function convertSchedulesToWeek(
  fromSchedules: ScheduleSchema[],
  toWeekBeginning: ISODateTimeString,
  options?: SetDayOptions
): ScheduleSchema[] {
  if (isEmpty(fromSchedules)) {
    return []
  } else {
    return fromSchedules.map((schedule) =>
      convertScheduleToWeek(schedule, toWeekBeginning, options)
    )
  }
}

export function convertScheduleToWeek(
  fromSchedule: ScheduleSchema,
  toWeekBeginning: ISODateTimeString,
  options?: SetDayOptions
): ScheduleSchema {
  const toWeekBeginningDate = new Date(toWeekBeginning)
  const fromEventStart = new Date(fromSchedule.event_start)
  const eventStartDay = getDay(fromEventStart)

  const fromEventEnd = fromSchedule.event_end
    ? new Date(fromSchedule.event_end)
    : null

  const toEventStart = set(
    setDay(toWeekBeginningDate, eventStartDay, options),
    {
      hours: fromEventStart.getHours(),
      minutes: fromEventStart.getMinutes(),
    }
  )
  const toEventEnd = fromEventEnd
    ? set(
        setDay(
          toWeekBeginningDate,
          getDay(fromEventEnd),
          options
        ),
        {
          hours: fromEventEnd.getHours(),
          minutes: fromEventEnd.getMinutes(),
        }
      )
    : null

  return {
    ...fromSchedule,
    event_start: toEventStart.toISOString(),
    event_end: toEventEnd ? toEventEnd.toISOString() : null,
  }
}

export async function copySchedulesToWeek(
  db: SupabaseClient,
  userId: string,
  fromSchedules: ScheduleSchema[],
  toWeekBeginning: ISODateTimeString,
  options?: SetDayOptions
) {
  const newSchedules: ScheduleWithId[] = convertSchedulesToWeek(
    fromSchedules,
    toWeekBeginning,
    options
  )
    .map((data) => renewCreatedFields(userId, data))
    .map((data) => ({
      ...data,
      id: newId(),
    }))
    .filter(isScheduleInFuture)

  verbose(
    'copying schedules',
    fromSchedules,
    toWeekBeginning,
    newSchedules
  )
  await scheduleStore.upsert(db, newSchedules)
  return newSchedules
}
