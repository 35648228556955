import { isArrayWithLength } from '@pogokid/util'
import { isString } from '@pogokid/util/lodash'

export function isEntitiesListData(
  data: unknown
): data is { data: string[] } {
  return (
    !!data &&
    typeof data === 'object' &&
    'data' in data &&
    Array.isArray(data.data) &&
    (isArrayWithLength(data) ? isString(data[0]) : true)
  )
}
