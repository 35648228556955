import { SchemaWithId } from '@soniq/schema'
import { ScheduleSchema } from '../../.types/Schedule.schema'

export type ScheduleWithId = SchemaWithId<ScheduleSchema>

export enum ScheduleEventType {
  generic = 'generic',
  lesson = 'lesson',
  studentUnavailable = 'student_unavailable',
}

export const GENERIC_SCHEDULE_EVENT_TYPE = 'generic'
export type ScheduleGenericEventSchema = ScheduleSchema & {
  type: typeof GENERIC_SCHEDULE_EVENT_TYPE
}

export const LESSON_SCHEDULE_EVENT_TYPE = 'lesson'
export type ScheduleLessonEventSchema = ScheduleSchema & {
  type: typeof LESSON_SCHEDULE_EVENT_TYPE
  contact_id: string
}

export const STUDENT_UNAVAILABLE_EVENT_TYPE =
  'student_unavailable'
export type ScheduleStudentUnavailableEventSchema =
  ScheduleSchema & {
  type: typeof STUDENT_UNAVAILABLE_EVENT_TYPE
  contactId: string
}
