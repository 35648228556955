import { createContext } from 'react'

interface IntegrateProviderState {}

export interface IntegrateContextProps
  extends IntegrateProviderState {
  apiBaseUrl: string
}

export const IntegrateContext =
  createContext<IntegrateContextProps>({
    apiBaseUrl: 'http://localhost:30041/v1',
  })
