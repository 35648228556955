import wasm from '../.wasm/wasm_public_manager_scheduling_bg.wasm?url'
import init, {
  edit_schedule,
  move_schedule,
  scheduled_events_consolidated,
} from '../.wasm/wasm_public_manager_scheduling'

export type * from '../.wasm/wasm_public_manager_scheduling'

export type WasmModule = Awaited<ReturnType<typeof initWasm>>

export async function initWasm() {
  await init({ module_or_path: wasm })
  return {
    editSchedule: edit_schedule,
    moveSchedule: move_schedule,
    scheduledEventsConsolidated: scheduled_events_consolidated,
  }
}
