import { PropsWithChildren, useMemo } from 'react'
import { PaymentsContext, PaymentsContextProps } from './context'
import { initWasm, WasmModule } from '../wasm.ts'
import { WithWasm, WithWasmProps } from '@soniq/public-manager'

interface PaymentsProviderProps {
  apiBaseUrl: string
}

export const PaymentsManagerProvider = WithWasm(
  initWasm,
  ({
    children,
    apiBaseUrl,
    wasm,
  }: PropsWithChildren<
    PaymentsProviderProps & WithWasmProps<WasmModule>
  >) => {
    const value = useMemo((): PaymentsContextProps => {
      return {
        apiBaseUrl,
        manager: wasm,
      }
    }, [apiBaseUrl, wasm])

    return (
      <PaymentsContext.Provider value={value}>
        {children}
      </PaymentsContext.Provider>
    )
  }
)

PaymentsManagerProvider.displayName = 'PaymentsManagerProvider'
