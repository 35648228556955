import docx, { AlignmentType } from '@pogokid/docx'

export const docxStyles: docx.IStylesOptions = {
  paragraphStyles: [
    {
      id: 'Normal',
      name: 'Normal',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        font: 'Helvetica',
      },
    },
  ],
  default: {
    heading1: {
      run: {
        size: 36,
        bold: true,
        color: '#000000',
      },
      paragraph: {
        alignment: AlignmentType.CENTER,
        spacing: {
          after: 360,
        },
      },
    },
    heading2: {
      run: {
        size: 32,
        bold: true,
        color: '#000000',
      },
      paragraph: {
        alignment: AlignmentType.CENTER,
        spacing: {
          after: 240,
        },
      },
    },
  },
}
