import { createContext } from 'react'
import { initWasm } from '../wasm'

interface SchedulingProviderState {}

export interface SchedulingContextProps
  extends SchedulingProviderState {
  apiBaseUrl: string
  manager: Awaited<ReturnType<typeof initWasm>>
}

export const SchedulingContext =
  createContext<SchedulingContextProps>(null as never)
