import { createContext } from 'react'
import { initWasm } from '../wasm.ts'

interface PaymentsProviderState {}

export interface PaymentsContextProps
  extends PaymentsProviderState {
  apiBaseUrl: string
  manager: Awaited<ReturnType<typeof initWasm>>
}

export const PaymentsContext =
  createContext<PaymentsContextProps>(null as never)
