import { SupabaseClient } from '@pogokid/supabase'
import {
  ISODateTimeString,
  normaliseIsoDateTimeString,
} from '@pogokid/util/datetime'
import {
  RowOf,
  SupabaseReadWriteDeleteStore,
} from '@soniq/public-resource/supabase'
import {
  ScheduleEventType,
  ScheduleSchema,
  ScheduleWithId,
  validateSchedule,
} from '@soniq/schema-scheduling'
import { Database } from '@soniq/util-supabase'
import { VISIBLE_SCHEDULE_TYPES } from './types'
import { SchemaWithId } from '@soniq/schema'
import { Optional } from '@pogokid/util'

export class ScheduleStore extends SupabaseReadWriteDeleteStore<
  'schedule',
  ScheduleSchema
> {
  static new() {
    return new ScheduleStore('schedule', validateSchedule)
  }

  protected mapQueryData<Returns = SchemaWithId<ScheduleSchema>>(
    data: Optional<Array<RowOf<'schedule'> | ScheduleSchema>>
  ): Returns[] {
    const returns = super.mapQueryData(data)
    return returns.map((row) => ({
      ...row,
      event_start: normaliseIsoDateTimeString(row.event_start),
      event_end: normaliseIsoDateTimeString(row.event_end),
    })) as Returns[]
  }

  /**
   * All schedules which are visible on the
   * calendar by default.
   *
   * Schedules like StudentUnavailable are not in
   * this by default because they are only shown in specific
   * circumstances
   */
  async fetchVisibleSchedulesEndAfter(
    db: SupabaseClient,
    userId: string,
    after: ISODateTimeString,
    scheduleEventTypes: ScheduleEventType[] = VISIBLE_SCHEDULE_TYPES
  ) {
    if (!after) {
      throw new Error('Invalid after date to find schedules for')
    }
    return this.runQuery(
      db,
      async (table) =>
        await table
          .select<'*', ScheduleWithId>()
          .is('deleted_at', null)
          .eq('created_by', userId)
          .gte('event_end', after)
          .in('type', scheduleEventTypes)
    )
  }
}

export const scheduleCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('schedule')
