import { lazy, Suspense } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { AppLoadingOrError } from '~m/AppUi/components/AppLoadingOrError.tsx'
import { SentryRoutes } from '~/instrument.ts'

const HomePage = lazy(async () => import('./pages/index'))
const HandlePage = lazy(
  () => import('./pages/[handle]/index.tsx')
)
const AccountIndex = lazy(() => import('./pages/account'))

// -----
const DashboardPage = lazy(() => import('./pages/dashboard'))
const FaqPage = lazy(() => import('./pages/faq'))
const GetStartedPage = lazy(() => import('./pages/getstarted'))
const IntegrationPage = lazy(
  () => import('./pages/integration/[handle]')
)
const InvitePage = lazy(
  () => import('./pages/invite/[id]/index.tsx')
)
const PayPage = lazy(
  () => import('./pages/pay/[link]/index.tsx')
)
const PricingPage = lazy(() => import('./pages/pricing'))
const PrivacyPage = lazy(() => import('./pages/privacy'))
const SignInPage = lazy(() => import('./pages/signin'))
const SubscribePage = lazy(() => import('./pages/subscribe'))

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoadingOrError />}>
        <SentryRoutes>
          <Route path="/" element={<HomePage />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route
            path="getstarted"
            element={<GetStartedPage />}
          />
          <Route path="faq" element={<FaqPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="signin" element={<SignInPage />} />
          <Route path="subscribe" element={<SubscribePage />} />
          <Route
            path="integration/:handle"
            element={<IntegrationPage />}
          />
          <Route path="invite/:id" element={<InvitePage />} />
          <Route path="pay/:link" element={<PayPage />} />
          <Route path="account/*">
            <Route path="*" element={<AccountIndex />} />
          </Route>
          <Route path=":handle/*">
            <Route path="*" element={<HandlePage />} />
          </Route>
        </SentryRoutes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
