import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
  useRoutes,
} from 'react-router-dom'
import { getPublicConfig } from '~/config.ts'

if (getPublicConfig().environment !== 'development') {
  Sentry.init({
    environment: getPublicConfig().environment,
    debug: localStorage.getItem('sentry_debug') === 'on',
    dsn: 'https://de52dea9da68c90dfebd3cde9276a980@o4506953947873280.ingest.us.sentry.io/4506953948004352',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 0.2,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      /^\//,
      /^https:\/\/[a-z-0-9]*api\.soniq\.studio$/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

export const useSentryRoutes = Sentry.wrapUseRoutesV7(useRoutes)
export const SentryRoutes =
  Sentry.withSentryReactRouterV7Routing(Routes)
