import { DURATION_MINUTE } from '@pogokid/util/datetime'
import { initConnectionManagerEntities } from '@soniq/public-manager-connection'
import { initPaymentsManagerEntities } from '@soniq/public-manager-payments/src/initPaymentsManagerEntities'
import { setSchedulingManagerEntityMutations } from '@soniq/public-manager-scheduling'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import {
  MutationCache,
  QueryClient,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { PropsWithChildren } from 'react'
import { getAllLogger } from '~/logger'
import { getPublicConfig } from '~/config.ts'

const log = getAllLogger('ui:app:TanstackQueryProvider')

const persister = createSyncStoragePersister({
  storage:
    typeof window === 'undefined'
      ? undefined
      : window.localStorage,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /**
       * The time in milliseconds after data is considered stale.
       * If set to `Infinity`, the data will never be considered stale.
       */
      staleTime: DURATION_MINUTE,
      /**
       * The time in milliseconds that unused/inactive cache data remains in memory.
       * When a query's cache becomes unused or inactive, that cache data will be garbage collected after this duration.
       * When different garbage collection times are specified, the longest one will be used.
       * Setting it to `Infinity` will disable garbage collection.
       */
      gcTime: 60_000 * 60 * 24, // 24 hours
      retry: 0,
    },
  },
  // configure global cache callbacks to show toast notifications
  mutationCache: new MutationCache({
    // onSuccess: (data) => {
    //   // toast.success(data.message);
    //   console.log('mutation cache success', data)
    // },
    onError: (error) => {
      // toast.error(error.message);
      log.error(error)
    },
  }),
})

export function TanstackQueryProvider({
  children,
}: PropsWithChildren) {
  const apiBaseUrl = getPublicConfig().apiBaseUrl
  const db = useSupabaseClient()
  initConnectionManagerEntities(apiBaseUrl, queryClient, db)
  initPaymentsManagerEntities(apiBaseUrl, queryClient, db)
  setSchedulingManagerEntityMutations(
    apiBaseUrl,
    queryClient,
    db
  )
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      onSuccess={() => {
        // resume mutations after initial restore from localStorage was successful
        queryClient.resumePausedMutations()
      }}
    >
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  )
}
