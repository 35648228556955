/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValidateSchemaOptions } from '@pogokid/schema'
import { newId, SupabaseClient } from '@pogokid/supabase'
import { useEnsureUserId, useSupabaseClient } from '@pogokid/supabase/react'
import { isArrayWithLength, Optional } from '@pogokid/util'
import { omit } from '@pogokid/util/lodash'
import { QueryResponse, SingleResponse, UpsertSchema } from '@soniq/public-resource'
import { SupabaseReadStoreOf, SupabaseReadWriteStore } from '@soniq/public-resource/supabase'
import { CreatedFields, isSchemaWithId, SchemaWithId } from '@soniq/schema'
import { MutationObserverOptions, QueryClient, useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useCallback } from 'react'
import { EntityActionParams, EntityKeys, EntityMutationParams, InferEntityKeyFilters } from './types'
import { setEntityDetailData, useEntitiesIndexedList, useEntityById } from './entityHooks'
import { useEntitiesDynamicIndexedList } from './entityHooks/useEntitiesList.ts'
import { getAllLogger } from '@pogokid/log'

const log = getAllLogger('useEntityHooks')

export const buildEntityKeys = <
  Filters extends Record<string, unknown> = Record<
    string,
    unknown
  >
>(
  allKey: string
): EntityKeys<Filters> => {
  const all = [allKey] as const
  const lists = () => [...all, 'list'] as const
  const details = () => [...all, 'detail'] as const
  const mutation = () => [...all, 'mutation'] as const
  return {
    all,
    lists,
    details,
    list: (filters: Partial<Filters> = {}) =>
      [...lists(), filters] as const,
    detail: (id: Optional<string>) =>
      [...details(), id] as const,
    mutation,
    isFilters(filters: unknown): filters is Filters {
      return !!filters && typeof filters === 'object'
    },
  }
}

export function buildEntityReadHooks<
  Schema extends CreatedFields,
  Keys extends EntityKeys<Record<string, unknown>>,
  Store extends SupabaseReadStoreOf<Schema>
>(entityKeys: Keys, store: Store) {
  return {
    useById(id: Optional<string>) {
      const db = useSupabaseClient()
      return useEntityById<SchemaWithId<Schema>, Keys>(
        id,
        entityKeys.detail(id),
        entityKeys,
        {
          queryFn: () => store.fetchById(db, id!),
        }
      )
    },
    // useByIds(ids: string[]) {
    //   const db = useSupabaseClient()
    //   return
    // },
    useByQuery(
      filters: InferEntityKeyFilters<Keys>,
      queryFilter?: (
        store: Store,
        filters: InferEntityKeyFilters<Keys>
      ) => Promise<QueryResponse<SchemaWithId<Schema>>>,
      options?: {
        enabled: boolean
      }
    ) {
      const db = useSupabaseClient()
      return useEntitiesIndexedList({
        listKey: entityKeys.list(filters),
        entityKeys,
        queryFn: () => {
          return queryFilter
            ? queryFilter(store, filters)
            : store.fetchQuery(db, filters)
        },
        options: {
          enabled: options?.enabled,
        },
      })
    },
  }
}

export function buildEntityHooks<
  Schema extends CreatedFields,
  Keys extends EntityKeys<Record<string, unknown>>,
  Store extends SupabaseReadWriteStore<any, Schema>
>(entityKeys: Keys, store: Store) {
  return {
    useValidate() {
      const validate = useCallback(
        (
          data: Schema & { id?: string },
          options?: ValidateSchemaOptions
        ) => {
          return store.validate(
            omit('id', data) as Schema,
            options
          )
        },
        []
      )
      const validateForm = useCallback(
        (
          data: Schema & { id?: string },
          options?: ValidateSchemaOptions
        ) => validate(data, options).errors,
        [validate]
      )
      return { validate, validateForm }
    },
    useById(id: Optional<string>) {
      const db = useSupabaseClient()
      return useEntityById<SchemaWithId<Schema>, Keys>(
        id,
        entityKeys.detail(id),
        entityKeys,
        {
          queryFn: () => store.fetchById(db, id!),
        }
      )
    },
    // useByIds(ids: string[]) {
    //   const db = useSupabaseClient()
    //   return
    // },
    useByQuery(
      filters: InferEntityKeyFilters<Keys>,
      queryFilter: (
        store: Store,
        filters: InferEntityKeyFilters<Keys>
      ) => Promise<QueryResponse<SchemaWithId<Schema>>>,
      options?: {
        enabled: boolean
      }
    ) {
      return useEntitiesIndexedList({
        listKey: entityKeys.list(filters),
        entityKeys,
        queryFn: () => queryFilter(store, filters),
        options: {
          enabled: options?.enabled,
        },
      })
    },
    useByQueries(
      filters: InferEntityKeyFilters<Keys>[],
      queryFilter: (
        store: Store,
        filters: InferEntityKeyFilters<Keys>
      ) => Promise<QueryResponse<SchemaWithId<Schema>>>,
      options?: {
        enabled: boolean
      }
    ) {
      return useEntitiesDynamicIndexedList({
        queries: filters.map((filter) => ({
          listKey: entityKeys.list(filter),
          queryFn: () => queryFilter(store, filter),
        })),
        entityKeys,
        options: {
          enabled: options?.enabled,
        },
      })
    },
    useMutation() {
      const userId = useEnsureUserId()
      return useEntityMutations<Schema, Keys>({
        entityKeys,
        userId,
      })
    },
  }
}

export function setEntityStoreMutations<
  Schema extends CreatedFields,
  Keys extends EntityKeys<Record<string, unknown>>,
  Store extends SupabaseReadWriteStore<any, Schema>
>({
  apiBaseUrl,
  queryClient,
  entityKeys,
  store,
  db,
}: {
  apiBaseUrl: string
  queryClient: QueryClient
  entityKeys: Keys
  store: Store
  db: SupabaseClient
}) {
  setEntityMutations<Schema, Keys>({
    queryClient,
    entityKeys,
    upsertEntities: async ({ data }) => store.upsert(db, data),
    updateEntity: async ({ data }) => store.update(db, data),
    deleteEntity: async () => {
      throw new Error('Delete not allowed')
    },
    actionEntity: async (_id, action) => {
      return store.action(db, {
        apiBaseUrl,
        data: action,
      })
    },
  })
}

/**
 * Default mutations for an entity so that paused
 * mutations can resume after a page reload
 */
export function setEntityMutations<
  Schema extends Record<any, any>,
  Keys extends EntityKeys<Record<string, unknown>>
>({
  queryClient,
  entityKeys,
  upsertEntities,
  updateEntity,
  deleteEntity,
  actionEntity,
}: {
  queryClient: QueryClient
  entityKeys: Keys
  upsertEntities: (
    data: EntityMutationParams<UpsertSchema<Schema>[]>
  ) => Promise<QueryResponse<SchemaWithId<Schema>>>
  updateEntity: (
    data: EntityMutationParams<SchemaWithId<Schema>>
  ) => Promise<SingleResponse<SchemaWithId<Schema>>>
  deleteEntity: (
    data: EntityMutationParams<null>
  ) => Promise<void>
  actionEntity?: (
    id: string,
    action: EntityActionParams
  ) => Promise<SingleResponse<SchemaWithId<any>>>
}) {
  if (
    queryClient.getMutationDefaults(entityKeys.mutation())
      .onMutate
  ) {
    return
  }
  const options: Omit<
    MutationObserverOptions<
      EntityMutationParams<
        | SingleResponse<SchemaWithId<Schema>>
        | QueryResponse<SchemaWithId<Schema>>
        | null
      >,
      any,
      EntityMutationParams,
      void
    >,
    'mutationKey'
  > = {
    mutationFn: async (params) => {
      const emptyResult: EntityMutationParams<null> = {
        ...params,
        data: null,
      }
      switch (params.operation) {
        case 'upsert':
          if (
            isArrayWithLength(params.data) &&
            isSchemaWithId(params.data[0])
          ) {
            return {
              ...params,
              data: await upsertEntities(
                params as EntityMutationParams<
                  SchemaWithId<Schema>[]
                >
              ),
            }
          }
          break
        case 'update':
          if (isSchemaWithId(params.data)) {
            return {
              ...params,
              data: await updateEntity(
                params as EntityMutationParams<
                  SchemaWithId<Schema>
                >
              ),
            }
          }
          break
        case 'delete':
          await deleteEntity(
            params as EntityMutationParams<null>
          )
          return emptyResult
        case 'action':
          if (actionEntity) {
            return {
              ...params,
              data: await actionEntity(
                params.id!,
                params.data as EntityActionParams
              ),
            }
          }
          break
        default:
          return emptyResult
      }
      return emptyResult
    },
    onMutate: async ({ id }) => {
      // cancel any queries for the id that is being mutated
      await queryClient.cancelQueries({
        queryKey: entityKeys.detail(id),
      })
      // TODO: DT 2024-02-04 create an optimistic entity
    },
    async onSuccess({ id, operation, data }) {
      switch (operation) {
        case 'upsert':
          if (isArrayWithLength(data?.data)) {
            log.verbose(
              'Invalidate lists after upsert',
              entityKeys.lists()
            )
            await queryClient.invalidateQueries({
              queryKey: entityKeys.lists(),
              type: 'all',
            })
            await setEntityDetailData(
              queryClient,
              entityKeys,
              data.data
            )
          }
          break
        case 'update':
          if (
            !Array.isArray(data?.data) &&
            isSchemaWithId(data?.data)
          ) {
            await queryClient.invalidateQueries({
              queryKey: entityKeys.lists(),
              type: 'all',
            })
            await queryClient.setQueryData(
              entityKeys.detail(data.data.id),
              data
            )
          }
          break
        case 'delete':
          queryClient.removeQueries({
            queryKey: entityKeys.detail(id),
          })
          break
      }
    },
  }
  queryClient.setMutationDefaults(entityKeys.mutation(), options)
}

/**
 * Mutations for an entity,
 * this also invalidates any cached
 * queries for the entity
 */
export function useEntityMutations<
  Schema extends Record<any, any>,
  Keys extends EntityKeys<Record<string, any>>,
  Actions = unknown
>(
  options: {
    entityKeys: Keys
    userId: string
  } & Pick<
    UseMutationOptions<
      SingleResponse<SchemaWithId<Schema>>,
      Error,
      Schema,
      never
    >,
    'gcTime' | 'retry' | 'retryDelay' | 'networkMode'
  >
) {
  const { entityKeys, userId, ...queryOptions } = options
  const { mutate, mutateAsync, ...mutation } = useMutation<
    EntityMutationParams,
    Error,
    EntityMutationParams,
    never
  >({
    ...queryOptions,
    mutationKey: entityKeys.mutation(),
  })

  return {
    ...mutation,
    upsert: useCallback(
      async (
        data: UpsertSchema<Schema>[]
      ): Promise<QueryResponse<SchemaWithId<Schema>>> => {
        const res = await mutateAsync(
          {
            id: null,
            operation: 'upsert',
            userId,
            data,
          },
          {}
        )
        return res.data as QueryResponse<SchemaWithId<Schema>>
      },
      [mutateAsync, userId]
    ),
    update: useCallback(
      (data: SchemaWithId<Partial<Schema>>) => {
        return mutateAsync({
          id: data.id,
          operation: 'update',
          userId,
          data,
        })
      },
      [mutateAsync, userId]
    ),
    action: useCallback(
      (data: Actions) => {
        return mutateAsync({
          id: newId(),
          operation: 'action',
          userId,
          data,
        })
      },
      [mutateAsync, userId]
    ),
  }
}
