import { SupabaseClient } from '@pogokid/supabase'
import * as Sentry from '@sentry/react'
import { isString, pickBy } from '@pogokid/util/lodash'

export interface FetchResponse<Data> {
  status: number
  data: Data | null
  error?: Error | null
}

export interface FetchWithAuthOptions<Body = undefined> {
  baseUrl?: string
  db: SupabaseClient
  method: 'get' | 'post' | 'put' | 'delete'
  endpoint: string
  body?: Body
}

function sentryHeaders() {
  const activeSpan = Sentry.getActiveSpan()
  const rootSpan = activeSpan
    ? Sentry.getRootSpan(activeSpan)
    : undefined

  // Create `sentry-trace` header
  const sentryTraceHeader = rootSpan
    ? Sentry.spanToTraceHeader(rootSpan)
    : undefined

  // Create `baggage` header
  const sentryBaggageHeader = rootSpan
    ? Sentry.spanToBaggageHeader(rootSpan)
    : undefined

  return pickBy(isString, {
    baggage: sentryBaggageHeader,
    'sentry-trace': sentryTraceHeader,
  })
}

export async function fetchWithAuth<Data, Body = undefined>({
  db,
  baseUrl,
  endpoint,
  method,
  body,
}: FetchWithAuthOptions<Body>): Promise<FetchResponse<Data>> {
  const {
    data: { session },
  } = await db.auth.getSession()

  const res = await fetch(`${baseUrl}${endpoint}`, {
    method: method.toUpperCase(),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session?.access_token}`,
      ...sentryHeaders(),
    },
    credentials: 'same-origin',
    body: body ? JSON.stringify(body) : undefined,
  })
  const data = await res.json()
  if (res.ok) {
    return { status: res.status, ...data }
  } else {
    return { status: res.status, data: null, error: data.error }
  }
}

export interface FetchJsonOptions<Body = undefined> {
  method: 'get' | 'post'
  baseUrl?: string
  endpoint: string
  query?: URLSearchParams
  body?: Body
}

export async function fetchJson<Data, Body = undefined>({
  baseUrl,
  endpoint,
  query,
  method,
  body,
}: FetchJsonOptions<Body>): Promise<FetchResponse<Data>> {
  const res = await fetch(
    `${baseUrl}${endpoint}${
      query?.size ? `?${query.toString()}` : ''
    }`,
    {
      method: method.toUpperCase(),
      headers: {
        'Content-Type': 'application/json',
        ...sentryHeaders(),
      },
      credentials: 'same-origin',
      body: body ? JSON.stringify(body) : undefined,
    }
  )
  const data = await res.json()
  if (res.ok) {
    return { status: res.status, ...data, data: data.data }
  } else {
    return { status: res.status, data: null, error: data.error }
  }
}
